import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import data from "./data/db.json";

function ProjectDetails() {
  const [visibleImage, setVisibleImage] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);

  const { id } = useParams();

  const project = data.projects.find((project) => project.id === id);

  const imageStyles = {
    height: project?.details?.imageHeight,
    marginLeft: project?.details?.leftAdjustment,
    marginTop: project?.details?.topAdjustment,
    width: project?.details?.imageWidth,
  };

  const altTag =
    `device showing screen from ${project.headline} experience`.toLowerCase();

  useEffect(() => {
    let interval;

    if (autoPlay) {
      interval = setInterval(() => {
        if (visibleImage + 1 >= project.details.images.length) {
          setVisibleImage(0);
        } else {
          setVisibleImage(visibleImage + 1);
        }
      }, 4000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [autoPlay, project, visibleImage]);

  return (
    <>
      <h3 className="details-title">{project.headline}</h3>
      <p className="details-description">{project.summary}</p>
      <div className={"details-image-container " + project.type}>
        <div className="dots">
          {project.details.images.map((img, index) => (
            <button
              className="dot-container"
              key={img}
              onClick={() => {
                setVisibleImage(index);
                setAutoPlay(false);
              }}
            >
              <div
                className={visibleImage === index ? "dot dot-active" : "dot"}
              ></div>
            </button>
          ))}
        </div>
        {project.details.images.map((img, index) => (
          <img
            alt={altTag}
            className={
              index === visibleImage ? "details-image visible" : "details-image"
            }
            key={img}
            src={require("./images/" + img).default}
            style={imageStyles}
          />
        ))}
      </div>
      <p className="details-description">{project.description}</p>
      {project.role && <p className="details-description">{project.role}</p>}
    </>
  );
}

export default ProjectDetails;
