function Intro() {
  return (
    <section className="intro">
      <h2 className="intro-headline">
        Building better <br />
        digital products
      </h2>
      <p className="intro-description">
        I'm a software engineer based in Philadelphia. I help brands build
        engaging and modern experiences.
      </p>
    </section>
  );
}

export default Intro;
