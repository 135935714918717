import About from "./About";
import Footer from "./Footer";
import Header from "./Header";
import ProjectDetails from "./ProjectDetails";
import Projects from "./Projects";
import ScrollToTop from "./ScrollToTop";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="wrapper">
        <Header />
        <Switch>
          <Route exact path="/">
            <Projects />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/project/:id">
            <ProjectDetails />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
