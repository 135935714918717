import { Link } from "react-router-dom";

function Header() {
  return (
    <header>
      <Link to="/">
        <h1>B</h1>
      </Link>
      <nav>
        <Link to="/" className="nav-link">
          Projects
        </Link>
        <Link to="/about" className="nav-link">
          About
        </Link>
        <a href="mailto:brettrholland@icloud.com" className="nav-link">
          Contact
        </a>
      </nav>
    </header>
  );
}

export default Header;
