import Intro from "./Intro";
import Project from "./Project";
import data from "./data/db.json";

function Projects() {
  return (
    <>
      <Intro />
      <div className="projects">
        {data.projects.map((project) => (
          <Project project={project} key={project.id} />
        ))}
      </div>
    </>
  );
}

export default Projects;
