import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <>
      <div className="contact">
        <div className="contact-text">
          <h3 className="contact-headline">Want to chat?</h3>
          <p className="contact-description">
            I enjoy meeting new people and talking about technology.
          </p>
        </div>
        <a href="mailto:brettrholland@icloud.com">
          <div className="contact-button">
            Email me <FontAwesomeIcon icon={faArrowRight} className="arrow" />
          </div>
        </a>
      </div>
      <footer>
        <p className="footer-copyright">© 2021 Brett Holland</p>
        <div className="footer-links">
          <a
            href="https://github.com/BrettRHolland"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            GitHub
          </a>

          <a
            href="https://www.linkedin.com/in/brettrholland/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            LinkedIn
          </a>
        </div>
      </footer>
    </>
  );
}

export default Footer;
