function About() {
  return (
    <>
      <h3 className="details-title">Hello!</h3>
      <p className="details-description">
        I am a software engineer that occasionally enjoys design. I have been
        building apps and websites for nearly 10 years.
      </p>
      <p className="details-description">
        I currently work at Smith Micro Software, where I primarily use
        JavaScript and React to build modern and accessible apps for some of the
        world’s leading wireless carriers.
      </p>
      <p className="details-description">
        Previously, I worked for Exelon where I led the end-to-end redesign of
        four customer-facing websites built on SharePoint and oversaw the
        development of a brand marketing hub using Drupal.
      </p>
      <p className="details-description">
        Before that, I worked with American Water and the American Red Cross.
      </p>
      <p className="details-description">
        I have experience with HTML, CSS, Sass, JavaScript, React, React Native,
        Ruby, and Ruby on Rails. I’ve also used Adobe Creative Suite, Figma, and
        Sketch.
      </p>
    </>
  );
}

export default About;
