import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Project({
  project: {
    grid: {
      columns,
      image,
      imageHeight,
      imageWidth,
      leftAdjustment,
      topAdjustment,
    },
    headline,
    preview,
    id,
  },
}) {
  const [isHovered, setIsHovered] = useState(false);

  const divStyles = {
    gridColumn: `auto / span ${columns}`,
  };

  const imageStyles = {
    height: imageHeight,
    marginLeft: leftAdjustment,
    marginTop: topAdjustment,
    width: imageWidth,
  };

  const altTag = `device showing screen from ${headline} experience`.toLowerCase();

  return (
    <Link
      to={/project/ + id}
      style={divStyles}
      className="project"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={
          isHovered ? "project-info project-info-visible" : "project-info"
        }
      >
        <p className="project-preview">{preview}</p>
        <button className="explore">
          Explore Project{" "}
          <FontAwesomeIcon icon={faArrowRight} className="arrow" />
        </button>
      </div>

      <img
        alt={altTag}
        key={image}
        src={require("./images/" + image).default}
        style={imageStyles}
      />
    </Link>
  );
}

export default Project;
