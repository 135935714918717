import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import "./fonts/Graphik-Black-Web.woff";
import "./fonts/Graphik-Bold-Web.woff";
import "./fonts/Graphik-Medium-Web.woff";
import "./fonts/Graphik-Regular-Web.woff";
import "./fonts/Graphik-Semibold-Web.woff";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
